if(!window.vars){
    window.vars = {};
}

if(!window.utils){
    window.utils = {};
}



if(!window.functions){
    window.functions = {};
}


;(function($){
    //tab-item用active类名来表示高亮
    //tab-item-content使用current表示显示
	var Tab = function(tab){
		var _this = this;
		// 保存单个tab组件
		this.tab = tab;
		// 默认配置参数
		this.config = {
			"triggerType":"mouseover", // 用来定义鼠标的触发类型，是click还是mouseover
			"effect":"default", // 用来定义内容切换效果，是直接切换还是淡入淡出效果
			"invoke":1, // 默认展示第几个tab
			"auto":false // 定义tab是否自动切换，及自动切换时间间隔
		}
		// 如果配置参数存在，就扩展掉默认配置参数
		if(this.getConfig()){
			$.extend(this.config,this.getConfig());
		}
		
		// 保存tab标签列表、对应的内容列表
		this.tabItems = this.tab.find(".tab-item");
		this.contentItems = this.tab.find(".tab-item-content");

		// 保存配置参数
		var config = this.config;

		if(config.triggerType === "click"){
			this.tabItems.bind(config.triggerType,function(){
				_this.invoke($(this));
			})
		}else if(config.triggerType != "click"){
			this.tabItems.bind("mouseover",function(){
				_this.invoke($(this));
			})
		}

		// 自动切换功能，如果配置了时间，我们就根据时间间隔进行自动切换
		if(config.auto){
			// 定义一个全局的定时器
			this.timer = null;
			// 计数器
			this.loop = 0;

			this.autoPlay();

			this.tab.hover(function(){
				window.clearInterval(_this.timer);
			},function(){
				_this.autoPlay();
			})
		}

		// 设置默认显示第几个tab
		if(config.invoke > 1){
			this.invoke(this.tabItems.eq(config.invoke-1));
		}
	};

	Tab.prototype = {
		// 自动间隔时间切换
		autoPlay: function(){
			var _this = this,
				tabItems = this.tabItems, // 临时保存tab列表
				tabLength = tabItems.size(), // tab的个数
				config = this.config;
			this.timer = window.setInterval(function(){
				_this.loop ++;
				if(_this.loop >= tabLength){
					_this.loop = 0;
				};
				tabItems.eq(_this.loop).trigger(config.triggerType);
			},config.auto);
		},

		// 事件驱动函数
		invoke: function(currentTab){
			var _this = this;
            var index = currentTab.index();
			// tab选中状态
			currentTab.addClass('active').siblings().removeClass('active');
			// 切换对应的内容区域
			var effect = this.config.effect;
			var conItems = this.contentItems;
			if(effect === "default" || effect != "fade"){
				conItems.eq(index).addClass('current').siblings().removeClass('current');
			}else if(effect === "fade"){
				conItems.eq(index).fadeIn().siblings().fadeOut();
			};
			// 注意：如果参数配置了自动切换时间，要把当前的loop的值设置成当前tab的index
			if(this.config.auto){
				this.loop = index;
			}
		},

		// 获取配置参数
		getConfig: function(){
			// 拿一下tab elem节点上的data-config
			var config = this.tab.attr("data-config");
            // 确保有配置参数
			if(config&&config!=""){
				return $.parseJSON(config);
			}else{
				return null;
			}
		}
	};
	//图片验证与发送手机验证码
	var Verification = function (form,settings) {  
		var _this = this;
		this.wait = 60;
		this.form = form;
		this.config = settings;
		// settings必须包含img和url
		if(!settings.img || !settings.url){
			console.error('请检查配置参数是否正确。');
		}
		form.find('.validate-img').bind('click',function(){
			_this.loadImg(form,_this);
		})
		form.find('.send_code').bind('click',function(e){
			console.log(_this.sendCode)
			_this.sendCode(form,e,_this);
		})
	}
	Verification.prototype = {
		time: function (o,self) {
			var _this=this;
			if (self.wait == 0) {         
				o.html("发送验证码");
				self.wait = 60;
				o.attr('disabled', false);
			} else {
				o.html(self.wait + " s");
				self.wait--;
				setTimeout(function() {
					_this.time(o,self)
				},
				1000)
			}
		},
		loadImg: function (form,self) {  
			var img = form.find('.validate-img > img').attr('src', self.config.img)
				.load(function() {
					if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
						alert('验证码图片加载出错，请刷新');
					} else {
						form.find('.validate-img').html('').append(img);
					}
				});
			return false;
		},
		sendCode: function (form,e,self) {
			var _this=this;
			var code = form.find('.input-default input[name="verify"]').val();//验证码
            	e.preventDefault();
                if(self.wait != 60){
                    return;
                }
                var btn_o = form.find('a.send_code');
				var mobile = form.find('input[name=mobile]').val();//手机号
                $.ajax({
                    url: self.config.url + "?mobile=" + mobile + '&code=' + code,
                    beforeSend: function() {
                        btn_o.html('加载中');
                        btn_o.attr('disabled', true);
                        return true;
                    },
                    success: function(data) {
                        //成功状态，下面的是服务器返回的，数据库操作的状态
                        if(data.status == 1){
                            _this.time(btn_o,self);
                        }
                        else{
                            btn_o.html('获取验证码');
                            btn_o.attr('disabled', false);
							self.loadImg(form,self);
                        }
                        $.msgBox({
                            title: "提示",
                            content: data.info,
                            type: "info",
                        });
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                    	$.msgBox({
                            title: "错误",
                            content: $(XMLHttpRequest.responseText).find('h1').text(),
                            type: "error",
                        });
                        btn_o.html('获取验证码');
                        btn_o.attr('disabled', false);
						self.loadImg(form,self);                    
                    },
                });
		}
	}

	// 注册成jq方法
	$.fn.extend({
		tab: function(){
			this.each(function(){
				new Tab($(this));
			});
			return this;
		},
		verification: function (obj) {  
			this.each(function(){
				new Verification($(this),obj);
			});
			return this;
		}
	})

	window.Tab = Tab;
	window.Verification = Verification;
})(jQuery);
class AjaxPage{
    constructor(el, option = {}){
        this.$el = $(el);
        this.$pageEl = this.$el.find('a');
        this.initPage = this.$el.find('.page-number.current').text().trim();
        this.totalPage = this.$el.data('total-page');
        this.page = this.initPage;
        this.url = '';
        this.lockStatus = false;

        this.$ajaxPageBox = this.$el.prev();
        this.domIndex = this.$el.index('.ajax-page');

        this.$parent = this.$el.parent();

        let defOpt = {
            activePageClass: 'current',
        };

        this.option = Object.assign({}, defOpt, option);

        this.init();
    }

    init(){
        let that = this;

        this.initDom();
    
        this.$el.find('input[name=page]').on('keyup', function (e) {
            if(e.keyCode === 13){
                that.$el.find('a.confirm').trigger('click');
            }
        });

        this.$pageEl.on('click', function (e) {
            e.preventDefault();
            if(that.isLock()){
                return false;
            }
            that.lock();

            let $this = $(this);

            let newPage = that.getPage($this);
            if(!newPage){
                return false;
            }

            that.$parent.addClass('ajax-page-loading__active');
            $this.siblings().removeClass(that.option.activePageClass);
            $this.addClass(that.option.activePageClass);

            that.url = that.getUrl(newPage);

            $("html,body").animate({
                scrollTop: that.$parent.offset().top - 150
            }, 300);

            that.getData().then((res)=> {

                let $newDomPage = $(res).find('.ajax-page').eq(that.domIndex);

                that.$ajaxPageBox.empty()
                    .html($newDomPage.prev().children());
                that.$parent.removeClass('ajax-page-loading__active');

                let newId = `ajax-page-${parseInt(new Date().getTime() * Math.random() * Math.random())}`;
                $newDomPage.attr('id', newId);
                that.$el.replaceWith($newDomPage);
                new AjaxPage(`#${newId}`, that.option);

                that.unLock();
            });
        });

    }

    updatePage(){

    }

    initDom(){
        if(this.$parent.hasClass('ajax-page-loading')){
            return false;
        }
        this.$parent.addClass('ajax-page-loading')
            .append(`
            <i class="icon ajax-page-loading-icon"></i>
            <div class="loading-mask"></div>
        `);
    }

    isLock(){
        return this.lockStatus;
    }

    lock(){
        this.lockStatus = true;
        return true;
    }

    unLock(){
        this.lockStatus = false;
    }

    getPage($el){
        let opera = $el.data('opera');
        let page = this.page;
        switch (opera) {
            case 'prev':
                --page;
                break;
            case 'pages':
                page = $el.text().trim();
                break;
            case 'next':
                ++page;
                break;
            case 'custom':
                page = $el.prevAll('input[name=page]').val();
                break;
            default:
                page = 1;
        }
        page = Math.max(1, page);
        page = Math.min(this.totalPage, page);

        this.page = page;
        return page;
    }

    getData(){
        return $.ajax({
            url: this.url,
        }).success((res)=>{
            return res;
        });
    }

    getUrl(page){
        var search = window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };
}


if(!window.functions){
    window.functions = {};
}
window.functions.initLaydate = function (el,format = 'yyyy-MM-dd HH:mm:ss',defaultTime = '23:59:59',fnEnd = ()=>{}){
    if(!laydate){
        throw new Error('laydate is not loaded');
    }

    var year = new Date().getFullYear() + '',
        month = window.utils.toFixedNumber((new Date().getMonth() + 1),10) + '',
        day = window.utils.toFixedNumber(new Date().getDate(),10) + '',
        separatorYear = format[format.indexOf('yyyy') + 4],
        separatorMonth = format[format.indexOf('MM') + 2],
        separatorDay = format[format.indexOf('dd') + 2].trim() || '',
        currentDay = year + separatorYear + month + separatorMonth + day + separatorDay,
        isInitValue = !!$(el).val(),
        defTimeArr = defaultTime.indexOf(':') > -1 ? defaultTime.split(':') : [],
        value = isInitValue ? $(el).val() : currentDay + ' ' + defaultTime;


    //执行一个laydate实例

    let instance = laydate.render({
        elem: el,
        format: format,
        type: 'datetime',
        value: value,
        isInitValue: isInitValue,
        done: function (val) {
            if(val === ''){
                instance.config.value = '';
                clearVal();
            }
        },
        ready: function () {
            instance.config.dateTime.hours = defTimeArr[0];
            instance.config.dateTime.minutes = defTimeArr[1];
            instance.config.dateTime.seconds = defTimeArr[2];
        }
    });
    setTimeout(()=>{
        if(!isInitValue){ //isInitValue 配置不生效
            clearVal();
        }
        fnEnd(el,instance);
    },150);

    function clearVal() {
        $(el).attr({
            value: ''
        }).val('');
    }

}

if(!window.utils){
    window.utils = {};
}

window.utils.getUrlParams = function () {
    //以对象的形式返回url参数
    var url = location.search; //获取url中"?"符后的字串
    var res = {};
    if (url.indexOf("?") != -1) {
        var str = url.substr(1),
            strs = str.split("&"),
            strArr,
            key,
            val;
        for(var i = 0; i < strs.length; i ++) {
            strArr = strs[i].split("=");
            key = strArr[0];
            val= strArr[1];
            if(typeof key === 'undefined' || typeof val === 'undefined'){
                continue;
            }
            res[key] = decodeURIComponent(val);
        }
    }
    return res;
}

window.utils.toFixedNumber = function (number,compareVal){
    if(number < compareVal){
        number = String(number);
        compareVal = String(compareVal);
        let counter = compareVal.length - number.length;
        for(var i = counter;i > 0;i--){
            number = '0' + number;
        }
    }
    return number;
}


window.utils.cancelEnterKey = function (){
    $(window).on('keydown',(e)=>{
        if(e.keyCode === 13){
            return false;
        }
    });
}


$(function () {
	
	$('.tab-content-item.active').fadeIn('slow');
	$('.tab-wrapper .tab-list>li, .tab-wrapper .tab-list .tab-item').on('click', function () {
		var $this = $(this);
		var index = $this.index();
		
		if($(this).hasClass('active')){
			return false;
		}
		
		
		$this.addClass('active').siblings().removeClass('active');
		
		$this.parents('.tab-wrapper').find('.tab-content-item')
			.css({
				display: 'none'
			})
			.siblings().removeClass('active')
			.eq(index)
			.addClass('active')
			.fadeIn('slow');
	});
	
	if($('.perfect-scrollbar-container').length){
		new PerfectScrollbar('.perfect-scrollbar-container');
	}

    $('[data-href]').on('click', function (e){
        e.stopPropagation();
        e.preventDefault();
        var data = $(this).data();
        if(data.target === '_blank'){
            window.open(data.href);
        }else{
            window.location.href = data.href;
        }
        return false;
    });
});

class DownloadHtml2Image{
    constructor(btnSelector, config) {
        this.btnSelector = btnSelector;
        this.$btnSelector = $(btnSelector);
        this.DEFAULT_CONFIG = {
            touchSaveImage: false,
            fileName: 'richer.png',
        };
        this.config = Object.assign({}, this.DEFAULT_CONFIG, config);
        this.qrCodeDom = $(this.config.qrCodeDom).get(0);
        this.IMG_DATA = null;

        this.init();
    }

    init(){
        this.$btnSelector.attr('disabled', true);

        if(!this.config.touchSaveImage){
            this.initClickSaveImg();
        }


        this.initFile().then(()=>{
            this.setBtnEnable();
        }).then(()=>{
            if(this.config.touchSaveImage){
                this.$btnSelector.css({
                    cursor: 'auto',
                });
                var NEW_IMG_DOM = `<img src="${this.IMG_DATA}" />`;
                $(this.config.imgBoxSelector).empty().append(NEW_IMG_DOM);
            }
        });
    }

    initClickSaveImg(){
        if(this.qrCodeDom){
            this.imgLoad(this.qrCodeDom).then(()=>{
                this.bindClickEvent();
            });
        }else{
            this.bindClickEvent();
        }
    }

    imgLoad(img){
        if(img.complete){
            return Promise.resolve();
        }
        return new Promise((resolve, reject)=>{
            return $(img).on('load', resolve);
        });
    }

    createImg(){
        return html2canvas(document.querySelector(this.config.imgBoxSelector),{
            useCORS: true,
            async: false,
        });
    }

    setBtnDownload(){
        this.$btnSelector.text('已下载').attr('disabled', true);
        this.$btnSelector.addClass('gray');
        setTimeout( ()=> {
            this.$btnSelector.text(this.config.downloadText).attr('disabled', false);
            this.$btnSelector.removeClass('gray');
        },3000);
    }

    setBtnEnable(){
        this.$btnSelector.attr({
            disabled: false,
        }).text(this.config.downloadText);
        this.$btnSelector.removeClass('gray');
    }

    bindClickEvent(){
        $('body').on('click', this.btnSelector, ()=> {
            this.setBtnDownload();

            /*下面的为原生的保存，不带格式名*/
            // 这将会提示用户保存PNG图片
            // Canvas2Image.saveAsPNG(oCanvas);
            saveFile(this.IMG_DATA, this.config.fileName);
        });
    }

    initFile(){
        return this.createImg(this.config.imgBoxSelector).then((canvas)=> {
            return canvas;
        }).then((canvas)=>{
            /*自动保存为png*/
            // 获取图片资源
            this.IMG_DATA = Canvas2Image.saveAsPNG(canvas, true).getAttribute('src');
        });
    }

}








//编辑器设置
var editorInit={
    toolbars: [
        [
            'undo', //撤销
            'redo', //重做

            'bold', //加粗

            'italic', //斜体

            'underline', //下划线

            'paragraph', //段落格式

            'simpleupload', //单图上传

            'insertimage', //多图上传

            'justifyleft', //居左对齐

            'justifyright', //居右对齐

            'justifycenter', //居中对齐

            'justifyjustify', //两端对齐

            'insertorderedlist', //有序列表

            'insertunorderedlist', //无序列表

            'fullscreen', //全屏

        ]

    ],

    topOffset:0,

    pasteplain: true,//是否默认为纯文本粘贴。false为不使用纯文本粘贴，true为使用纯文本粘贴

    retainOnlyLabelPasted: true  //粘贴只保留标签，去除标签所有属性

}

var swiperDefaultConf = {
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
	pagination :{
		clickable :true,
	},
    loop: true
};

function initSwiper(container, confObj = {}) {
    var $container = $(container),
        conf = {},
	    pagination = {};
	
	confObj.pagination = confObj.pagination || {};
	Object.assign(pagination, swiperDefaultConf.pagination, confObj.pagination);
	Object.assign(conf, swiperDefaultConf, confObj);
	conf.pagination = pagination;
    
    if (!$container.length) {
        return false;
    }
    
    var mySwiper = new Swiper(container, conf);
    $(container).mouseleave(function () {
        mySwiper.autoplay.start();
    });
    
    return mySwiper;
}


$(function () {
    
    $('.tab-top-menu a').on('click', function () {
        $(this).parents('.tab-top-menu').find('a').removeClass('active');
        $(this).addClass('active');
    });

    $('.img-gallery').each((index, item)=>{
        let $el = $(item);
        let childTagName = '';
        try{
            childTagName = $el.children().first().get(0).tagName;
        }catch(e){
        }
        if(childTagName){
            $el.magnificPopup({
                delegate: childTagName,
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    titleSrc: function(item) {
                    }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300, // don't foget to change the duration also in CSS
                    opener: function(element) {
                        return element.find('img');
                    }
                }

            })
        }
    });
});


$(function () {
    var togglePage = function (e) {
        if(e.keyCode === 13){
            $('.pagination-box').not('.ajax-page').find('.confirm').trigger('click');
        }
    };
    $('.pagination-box input[name=page]').on('focus', function () {
        $(window).on('keyup', togglePage);
    }).on('blur', function () {
        $(window).off('keyup', togglePage);
    });
    
    var getUrl = function(page){
        var search=window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };

    $('.pagination-box').not('.ajax-page').find('.confirm').on('click',function () {
        var page=$(this).prevAll('input[name=page]').val();
        var totalPage = $(this).parents('.pagination-box').data('total-page');
        page = parseInt(page);
        if (!page){
            return;
        }
        page = Math.max(0, page);
        page = Math.min(totalPage, page);
        var url = getUrl(page);
        $(this).attr('href',url);
        window.location.href=url;
    });
    

    $('.ajax-page').each(function () {
        new AjaxPage(this);
    });
});

//根据点击的选项，显示相应的元素
function showRelativeFormItem(inputEle, relativeEle, activeVal = 0) {
    var val = Number($(inputEle + ':checked').val());
    var $relativeEle = $(relativeEle);
    activeVal = Number(activeVal);
    var flag=val === activeVal;
    if (isNaN(activeVal) && isNaN(val)){
        console.log(1);
        flag = true;
    }
    if(flag){
        $relativeEle.removeClass('hidden');
    }else{
        $relativeEle.addClass('hidden');
    }
}





